<template>
    <LoadQRLLIB />
</template>

<script>
import LoadQRLLIB from '@/components/LoadQRLLIB.vue';

export default {
  name: 'home',
  components: {
    LoadQRLLIB,
  },
};

</script>
